(function($, window) {
  'use strict';

  /**
   * Return an object with dynamic values
   * for the viewport width and height
   *
   * @return {Object} Object containing the width and height of the viewport
   */
  function Viewport() {
    if (!(this instanceof Viewport)) return new Viewport();

    // Object for the `resizeend` callbacks
    this.onResizeend = {};

    // Define dynamic property `width`
    Object.defineProperty(this, 'width', {
      get: function() {
        return window.innerWidth;
      },
    });

    // Define dynamic property `height`
    Object.defineProperty(this, 'height', {
      get: function() {
        return window.innerHeight;
      },
    });

    // Define dynamic property `breakpoint`
    Object.defineProperty(this, 'breakpoint', {
      get: function() {
        return window
          .getComputedStyle(document.body, '::before')
          .getPropertyValue('content')
          .replace(/"/g, '');
      },
    });

    // Bind events to window
    $(window).on({
      resize: resizeHandler(),
      resizeend: resizeendHandler.bind(this),
    });

    return this;
  }

  /**
   * Handler for the `resize` window event,
   * create a debounced event named `resizeend`
   *
   * @param {Object} e The event's object
   */
  function resizeHandler(e) {
    return debounce(300, false, function(e) {
      $(this).trigger(e.type + 'end');
    });
  }

  /**
   * Handler for the custom `resizeend` window event,
   * calls each function stored in the `instance.onResizeend` object
   *
   * @param {Object} e The event's object
   */
  function resizeendHandler(e) {
    for (var name in this.onResizeend) {
      var fn = this.onResizeend[name];
      if (typeof fn === 'function') {
        fn(this);
      }
    }
  }

  // Expose globally
  window.Viewport = Viewport;
})(jQuery, window);
